import React from 'react';

import ContentPage from '../../components/Home/content'

const HomePage = () => {
   
    return (
        <div>
            <ContentPage />

        </div>
    );
}

export default HomePage;
